@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: var(--red);
  color: var(--white);
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background-color: #E9E9E9;
  color: #FFFFFF;
}

img {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}